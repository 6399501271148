import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFile} from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import '../styles/legal.scss';


function LegalItem(props) {
    const {text, url} = props;

    return (
        <div className={"m-0 p-4 legal_box"} >
            <a hrefLang="ro" href={url} target={"_blank"} rel="noreferrer" >
                <FontAwesomeIcon
                    icon={faFile}
                    color={'#32a852'}
                    className={"mr-4 list_icon"}
                    title={text}
                />
                <span>{text}</span>
            </a>
        </div>
    )
}

export default LegalItem