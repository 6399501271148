import * as React from "react"
import {Grid} from "@material-ui/core";
import MainMenu from "../MainMenu";
import "./../../styles/index.scss"
import Seo from "../seo";
import "react-datepicker/dist/react-datepicker.css";
import '../../styles/contact.scss'
import '../../styles/general.scss'
import AppFooter from "../AppFooter";
import {Mixpanel} from "../Mixpanel";
import {useEffect, useMemo} from "react";
import LegalItem from "../LegalItem";
import Banner from "../Banner";

export default function ComponentLegal({data}) {
    const prismicContact = data.prismicContact

    useEffect(() => {
        Mixpanel.track('Page-Legislatie');
    }, [data])

    const seoData = {
        canonical: "https://www.cabinet-psiho.ro/legal/",
        title: 'Legislatie - Cabinet psihologic sector 6',
        description: 'Legislatie  - Cabinet psihologic Roxana Simonescu, psiholog atestat, Bucuresti, avize si sedinte online, terapie, hiphoza, clinica medicala',
        url: "https://www.cabinet-psiho.ro/legal/",
        metas: [
        ]
    }

    //@see https://psihoterapie.at/legislatie-in-domeniu/
    const legalEntries = [
        {
            'url': "https://legislatie.just.ro/Public/DetaliiDocumentAfis/147885",
            'text': 'Norme metodologice din 14 iulie 2005 (actualizate)'
        },
        {
            'url': "http://legislatie.just.ro/Public/DetaliiDocument/52375",
            'text': 'Legea Psihologului'
        },
        {
            'url': "http://legislatie.just.ro/Public/DetaliiDocumentAfis/147807",
            'text': 'Hotararea nr 215 din 30 aprilie 2013'
        },
        {
            'url': 'http://legislatie.just.ro/Public/DetaliiDocument/211656',
            'text': 'Norme din 11 ianuarie 2019'
        },
        {
            'url': 'https://lege5.ro/Gratuit/gm4teobsge/dispozitii-generale-lege-333-2003?dp=gy3teobsg4yte',
            'text': 'Legea 333 / 2003 – Legea privind paza obiectivelor, bunurilor, valorilor si protectia persoanelor'
        },
        {
            'url': 'http://www.jandarmeriasalaj.ro/legi/legea%20295%20arme.pdf',
            'text': 'Legea 295 /2004 – Legea privind regimul armelor si munitiilor',
        },
        {
            "url": "https://legislatie.just.ro/Public/DetaliiDocumentAfis/129609",
            "text": "Lega 117 /2011 – Legea pentru modificarea si completarea Legii 295 privind regimul armelor si munitiilor"
        }
    ]
    const renderUsingMemo = () => (
        <div>
            <Seo title="Contact" seoData={seoData}/>
            <Grid container>
                <MainMenu pageName={"legislatie"} prismicContact={prismicContact}/>

                <Banner />

                <Grid item sm={12} xl={12} lg={12} md={12}
                      className={` p-4 pl-64 pr-64 pt-64 w-full`}
                >
                    <div className={"flex flex-row justify-content-center align-middle align-items-center mb-8"}>
                        <div className={"heading_1 primary_heading_1_title color_green_light font-bold text_green_light_color font_size_xxl"} >
                            Legislatie
                        </div>
                    </div>


                    <Grid container className={"w-full justify-content-center align-middle align-content-center"} direction={"row"}>
                        <div className={"flex flex-column justify-content-center align-middle align-content-center"}>
                            {legalEntries.map((legalEntry, lk) => (
                                <LegalItem key={lk} url={legalEntry.url} text={legalEntry.text} />
                            ))}
                        </div>
                    </Grid>
                </Grid>

                <AppFooter prismicContact={prismicContact}/>
            </Grid>
        </div>
    )

    const memoizedValue = useMemo(() => renderUsingMemo(), [prismicContact]);
    return (<>{memoizedValue}</>)
}
